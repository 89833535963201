jQuery(document).ready(function( $ ) {

  // wrap menu items
  $('ul.sub-menu').wrap('<div class="pc-sub-menu"></div>');

  // open menu
  $('.pc-menu-open').on('click',function(e){
    e.preventDefault();
    $('body').addClass('pc-frozen');
    $('.pc-nav-wrapper .pc-menu-wrapper').addClass('open');
  });

  // close menu
  $('.pc-menu-close').on('click',function(e){
    e.preventDefault();
    $('body').removeClass('pc-frozen');
    $('.pc-nav-wrapper .pc-menu-wrapper').removeClass('open');
  });

  // mobile menu
  $('li.menu-item-has-children.show').on('click',function(){
    $(this).removeClass('show');
  });

  $('li.menu-item-has-children:not(.show)').on('click',function(){
    $('li.menu-item-has-children').not(this).removeClass('show');
    $(this).toggleClass('show');
  });

  $('.pc-nav-wrapper .pc-menu-wrapper:not(.open) nav ul.menu > li.menu-item-has-children > a').on('click',function(e){
    e.preventDefault();
    $(this).next().toggleClass('open');
  });

});
